#bim-category {

  width: 100%;
  max-width: 1300px;

  /* 旧style.css */
  /*内容エリア*/
  .caption {
    margin: 0.5px auto;
    /*padding: 2px;*/
    /*border: 1px solid #ccc;*/
    line-height: 1.3;
    color: #444;
    width: 1300px;
    /*body幅に合わせる*/
    background-color: #fcfcfc;
    z-index: auto;
  }

  /*文字サイズ個別指定(ただしHTML全体は指定なき場合12ptかつ游ゴシックと指定している。)*/
  .moji12 {
    font-size: 12pt;
  }

  .moji16 {
    font-size: 16pt;
  }

  .moji18 {
    font-size: 18pt;
  }

  /*動作環境の表*/
  .kankiyou_list {
    border: 1px solid black;
    font-family: HG丸ｺﾞｼｯｸM-PRO;
    font-size: 12pt;
    background-color: aliceblue;
    border-collapse: collapse;
  }

  .kankiyou_list td {
    border: 1px solid #000000;
  }

  ul.button {
    margin: 0px;
    padding-left: 25px;
  }

  li.button {
    margin: 2px;
    padding: 0px;
  }

  /*タイトル部*/
  .model_title {
    font-size: 16pt;
    font-family: "Arial";
    /*フォント*/
    font-weight: bold;
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: solid 2px #6594e0;
    border-bottom-width: 4px;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  /*行間*/
  .line {
    line-height: 35px;
  }

  /*行間35px(liの中のみ)、文上下スペース15ｐｘ*/
  .line01 {
    line-height: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  /*行間(liの中のみ)*/
  .line02 {
    line-height: 25px;
  }

  /*リスト項目行間マージン*/

  .list01 li {
    margin-bottom: 20px;
  }

  h1.midashi1 {
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: dashed 2px #6594e0;
  }

  h2.midashi2 {
    padding: 0.25em 0.5em;
    /*上下 左右の余白*/
    color: #494949;
    /*文字色*/
    background: transparent;
    /*背景透明に*/
    border-left: solid 5px #7db4e6;
    /*左線*/
  }

  h3.midashi3 {
    background: linear-gradient(transparent 70%, #a7d6ff 70%);
  }

  h3.midashi4 {
    padding: 1rem 2rem;
    border-left: 5px solid #000;
    background: #f4f4f4;
  }

  h5 {
    position: relative;
    /*相対位置*/
    padding-left: 1.5em;
    /*アイコン分のスペース*/
    line-height: 1.4;
    /*行高*/
    color: black;
    /*文字色*/
  }

  h5:before {
    font-family: "ＭＳ Ｐゴシック";
    /*忘れずに*/
    content: "\f111";
    /*アイコンのユニコード*/
    font-weight: 900;
    position: absolute;
    /*絶対位置*/
    font-size: 1.4em;
    /*サイズ*/
    left: 0;
    /*アイコンの位置*/
    top: -0.2em;
    /*アイコンの位置*/
  }

  /*ここから注意(check)の見出し*/
  .check {
    position: relative;
    color: black;
    background: #d0ecff;
    line-height: 1.4;
    padding: 0.25em 0.5em;
    margin: 2em 0 0.5em;
    border-radius: 0 5px 5px 5px;
  }

  .check:after {
    /*タブ*/
    position: absolute;
    /*
      font-family: "Font Awesome 5 Free",'Quicksand','Avenir','Arial',sans-serif;
      */
    font-weight: 900;
    content: "Check";
    background: #2196f3;
    color: #fff;
    left: 0px;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    padding: 3px 7px 1px;
    font-size: 0.9em;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  /*バージョン部背景着色*/
  .version {
    background-color: #ffff00;
    font-size: large;
    font-weight: bold;
  }

  /*社外秘協調*/
  .shiyagai {
    background-color: #ffff00;
    font-weight: bold;
    color: #ff0000;
  }

  /*表内中央揃え*/
  .tablearea {
    text-align: center;
    line-height: 120%;
  }

  /*メールアドレス部分青太字*/
  .mail {
    color: #00bfff;
    /*文字色*/
    font-weight: bold;
  }

  h1.midashi1 {
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: dashed 2px #6594e0;
  }

  h2.midashi2 {
    padding: 0.25em 0.5em;
    /*上下 左右の余白*/
    color: #494949;
    /*文字色*/
    background: transparent;
    /*背景透明に*/
    border-left: solid 5px #7db4e6;
    /*左線*/
    font-size: 25px;
    font-weight: bold;
  }

  h3.midashi3 {
    background: linear-gradient(transparent 70%, #a7d6ff 70%);
  }

  h3.midashi4 {
    padding: 1rem 2rem;
    border-left: 5px solid #000;
    background: #f4f4f4;
  }

  /* 旧table.css */
  table {
    font-family: HG丸ｺﾞｼｯｸM-PRO;
    font-size: 12pt;
    border-collapse: collapse;
    margin: 0 auto;
    padding: 0;
    width: 1100px;
    box-shadow: 0 0 15px -6px #00000073;
  }

  table tr {
    background-color: #fff;
    /*内容全域の背景色*/
  }

  table tbody tr:hover {
    background-color: aliceblue;
    /*選択時の背景色*/
  }

  table th,
  table td {
    padding: 0.35em .75em;
    border-bottom: 1px solid #eee;
    /*横線の色、太さ*/
  }

  table thead th {
    font-size: 0.85em;
    padding: 1em;
    text-align: center;
  }

  table thead tr {
    background-color: #3399ff;
    /*項目の背景色*/
    color: #fff;
  }

  table tbody th {
    text-align: left;
    /*全体の寄せ*/
    font-size: 0.8em;
  }

  .nomal_table {
    width: 1200px;
  }

  .nomal_table th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
  }

  .nomal_table td {
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
  }

  .nomal_table_allLine {
    width: 1200px;
  }

  .nomal_table_allLine th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
    border-bottom-color: #000000;
  }

  .nomal_table_allLine td {
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
    border-bottom-color: #000000;
  }

  /*テーブル背景色(交互) oddで奇数、evenで偶数に対して指定（02-4データ互換性）*/
  .t-line th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
  }

  .t-line tr:nth-child(odd) td {
    background-color: aliceblue;
  }

  .t-line tr:nth-child(even) td {
    background-color: #caeeff;
  }

  /*テーブル背景色(交互) oddで奇数、evenで偶数に対して指定（02-1担当者）*/
  .t-tantou th {
    background-color: #00bb85;
    font-weight: bold;
    font-family: HG丸ｺﾞｼｯｸM-PRO;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
  }

  .t-tantou tr:nth-child(odd) td {
    background-color: #ffffe5;
    text-align: left;
    height: 53px;
  }

  .t-tantou tr:nth-child(even) td {
    background-color: #e5ffe5;
    text-align: left;
    height: 53px;
  }

  @media screen and (max-width: 600px) {
    table {
      width: 100%;
    }
  }

  .titlefont {
    font-weight: normal;
  }

  /*部材セット等DL表*/
  .ex1 {
    line-height: 20px;
  }

  .ex2 {
    /*２段重ねの表はこれを使うこと*/
    line-height: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablename {
    font-size: 16pt;
    width: 1200px;
    margin-bottom: 10px;
    border-bottom: inherit;
    border-bottom-color: #c0c0c0;
    border-bottom-style: solid;
    border-bottom-width: 5px;
  }

  /*表内箇条書き設定*/
  .table-ul ul {
    padding-left: 5px;
    margin-left: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 17pt;
    /*
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px
  */
    /*↑padding: 10px 10px 10px 30pxと
  まとめて書くこともできますね。*/
    /*背景色などのCSSは省略*/
  }

  .table-li li {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* 旧button.css */

  /*
body:before{
  content:'';
  height:100%;
  display:inline-block;
  vertical-align:middle;
}
*/

  /*ボタンオレンジ表示*/
  button.imaging {
    background: #ffa500;
    color: #fff;
    border: none;
    position: relative;
    height: 30px;
    font-size: 1.1em;
    padding: 0 1em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    display: inline-block;
  }

  button.imaging:hover {
    background: #fff;
    color: #ffa500;
  }

  button.imaging:before,
  button:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #ffa500;
    transition: 400ms ease all;
  }

  /*
   button.imaging:after{
     right:inherit;
     top:inherit;
     left:0;
     bottom:0;
   }
   button.imaging:hover:before,button:hover:after{
     width:100%;
     transition:800ms ease all;
   }
   */

  /*オレンジ表示ここまで*/

  /*ボタングリーン表示*/
  button {
    background: #1aab8a;
    color: #fff;
    border: none;
    position: relative;
    height: 30px;
    font-size: 1.1em;
    padding: 0 1em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    white-space: nowrap;
  }

  button:hover {
    background: #fff;
    color: #1aab8a;
  }

  button:before,
  button:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #1aab8a;
    transition: 400ms ease all;
  }

  /*
   button:after{
     right:inherit;
     top:inherit;
     left:0;
     bottom:0;
   }
   button:hover:before,button:hover:after{
     width:100%;
     transition:800ms ease all;
   }

    */
  /*ボタングリーン表示ここまで*/

  /*********/
  /***** button_1 ****/
  /*********/

  /*
body:before{
  content:'';
  height:100%;
  display:inline-block;
  vertical-align:middle;
}
*/


  /*ボタンオレンジ表示*/
  button.imaging {
    background: #FFA500;
    color: #fff;
    border: none;
    position: relative;
    height: 30px;
    font-size: 1.1em;
    padding: 0 1em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
  }


  button.imaging:hover {
    background: #fff;
    color: #FFA500;
  }

  button.imaging:before,
  button:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #FFA500;
    transition: 400ms ease all;
  }

  /*
 button.imaging:after{
   right:inherit;
   top:inherit;
   left:0;
   bottom:0;
 }
 button.imaging:hover:before,button:hover:after{
   width:100%;
   transition:800ms ease all;
 }
 */


  /*オレンジ表示ここまで*/



  /*ボタングリーン表示*/
  button {
    background: #1AAB8A;
    color: #fff;
    border: none;
    position: relative;
    height: 30px;
    font-size: 1.1em;
    padding: 0 1em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
  }

  button:hover {
    background: #fff;
    color: #1AAB8A;
  }

  button:before,
  button:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #1AAB8A;
    transition: 400ms ease all;
  }

  /*
 button:after{
   right:inherit;
   top:inherit;
   left:0;
   bottom:0;
 }
 button:hover:before,button:hover:after{
   width:100%;
   transition:800ms ease all;
 }
  */

  /*ボタングリーン表示ここまで*/

  /*利用状況記録リンクボタン*/

  /*四角角丸*/
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .btn,
  a.btn,
  button.btn {
    font-size: 13pt;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    padding: 1rem 4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    letter-spacing: 0.1em;
    color: #212529;
    border-radius: 0.5rem;
  }

  .btn--lightpink,
  a.btn--lightpink {
    color: #191970;
    background-color: #ffb6c1;
  }

  .btn--lightpink:hover,
  a.btn--lightpink:hover {
    color: #fff;
    background: #ffb6c1;
  }

  .btn--mistyrose,
  a.btn--mistyrose {
    color: skyblue;
    background-color: #ffe4e1;
  }

  .btn--mistyrose:hover,
  a.btn--mistyrose:hover {
    color: #fff;
    background: #ffe4e1;
  }

  /*********/
  /***** style ****/
  /*********/

  /*全体(BODY)フォントとフォントサイズ標準設定*/
  html {
    font-size: 12.0pt;
    font-family: 'メイリオ', 'Meiry', sans-serif;
    font-weight: 400;
  }

  /*内容エリア*/
  .caption {
    margin: 0.5px auto;
    /*padding: 2px;*/
    /*border: 1px solid #ccc;*/
    line-height: 1.3;
    color: #444;
    width: 1300px;
    /*body幅に合わせる*/
    background-color: #fcfcfc;
    z-index: auto;

  }

  /*文字サイズ個別指定(ただしHTML全体は指定なき場合12ptかつ游ゴシックと指定している。)*/
  .moji12 {
    font-size: 12pt;
  }

  .moji16 {
    font-size: 16pt;
  }

  .moji18 {
    font-size: 18pt;
  }


  /*動作環境の表*/
  .kankiyou_list {
    border: 1px solid black;
    font-family: 'メイリオ', 'Meiry', sans-serif;
    font-size: 12pt;
    background-color: aliceblue;
    border-collapse: collapse;
  }

  .kankiyou_list td {
    border: 1px solid #000000;
  }

  ul.button {
    margin: 0px;
    padding-left: 25px;
  }

  li.button {
    margin: 2px;
    padding: 0px;
  }

  /*タイトル部*/
  .model_title {
    font-size: 16pt;
    font-family: 'メイリオ', 'Meiry', sans-serif;
    font-weight: bold;
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    ;
    border-bottom: solid 2px #6594e0;
    border-bottom-width: 4px;
    text-align: left;
    margin: 0;
    padding: 0;

  }

  /*行間*/
  .line {
    line-height: 35px;
  }

  /*行間35px(liの中のみ)、文上下スペース15ｐｘ*/
  .line01 {
    line-height: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
  }


  /*行間(liの中のみ)*/
  .line02 {
    line-height: 25px;
  }

  /*リスト項目行間マージン*/

  .list01 li {
    margin-bottom: 20px;
  }


  h1.midashi1 {
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: dashed 2px #6594e0;
  }

  h2.midashi2 {
    padding: 0.25em 0.5em;
    /*上下 左右の余白*/
    color: #494949;
    /*文字色*/
    background: transparent;
    /*背景透明に*/
    border-left: solid 5px #7db4e6;
    /*左線*/
  }

  h3.midashi3 {
    background: linear-gradient(transparent 70%, #a7d6ff 70%);
  }

  h3.midashi4 {
    padding: 1rem 2rem;
    border-left: 5px solid #000;
    background: #f4f4f4;
  }

  h5 {
    position: relative;
    /*相対位置*/
    padding-left: 1.5em;
    /*アイコン分のスペース*/
    line-height: 1.4;
    /*行高*/
    color: black;
    /*文字色*/
  }

  h5:before {
    font-family: "ＭＳ Ｐゴシック";
    /*忘れずに*/
    content: "\f111";
    /*アイコンのユニコード*/
    font-weight: 900;
    position: absolute;
    /*絶対位置*/
    font-size: 1.4em;
    /*サイズ*/
    left: 0;
    /*アイコンの位置*/
    top: -0.2em;
    /*アイコンの位置*/
  }

  /*ここから注意(check)の見出し*/
  .check {
    position: relative;
    color: black;
    background: #d0ecff;
    line-height: 1.4;
    padding: 0.25em 0.5em;
    margin: 2em 0 0.5em;
    border-radius: 0 5px 5px 5px;
  }

  .check:after {
    /*タブ*/
    position: absolute;
    /*
  font-family: "Font Awesome 5 Free",'Quicksand','Avenir','Arial',sans-serif;
  */
    font-weight: 900;
    content: 'Check';
    background: #2196F3;
    color: #fff;
    left: 0px;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    padding: 3px 7px 1px;
    font-size: 0.9em;
    line-height: 1;
    letter-spacing: 0.05em
  }

  /*バージョン部背景着色*/
  .version {
    background-color: #FFFF00;
    font-size: large;
    font-weight: bold;
  }

  /*社外秘協調*/
  .shiyagai {
    background-color: #FFFF00;
    font-weight: bold;
    color: #FF0000;
  }

  /*表内中央揃え*/
  .tablearea {
    text-align: center;
    line-height: 120%;
  }

  /*メールアドレス部分青太字*/
  .mail {
    color: #00bfff;
    /*文字色*/
    font-weight: bold;

  }

  h1.midashi1 {
    color: #6594e0;
    /*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: dashed 2px #6594e0;
  }

  h2.midashi2 {
    padding: 0.25em 0.5em;
    /*上下 左右の余白*/
    color: #494949;
    /*文字色*/
    background: transparent;
    /*背景透明に*/
    border-left: solid 5px #7db4e6;
    /*左線*/
  }

  h3.midashi3 {
    background: linear-gradient(transparent 70%, #a7d6ff 70%);
  }

  h3.midashi4 {
    padding: 1rem 2rem;
    border-left: 5px solid #000;
    background: #f4f4f4;
  }


  /*********/
  /***** table_button ****/
  /*********/
  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: none;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 160px;
    /*タイルの幅　個数ごとに個別指定*/
    grid-template-rows: 150px;
    /*タイルの高さ　個数ごとに個別指定*/

  }


  /*********/
  /***** table ****/
  /*********/
  body {
    /*font-family:HG丸ｺﾞｼｯｸM-PRO;*/
    line-height: 1.25;
  }

  /*部材ダウンロードのテーブル*/
  table {
    font-family: HG丸ｺﾞｼｯｸM-PRO;
    font-size: 12.0pt;
    border-collapse: collapse;
    margin: 0 auto;
    padding: 0;
    width: 1100px;
    box-shadow: 0 0 15px -6px #00000073;

  }

  .yoko {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }


  table tr {
    background-color: #fff;
    /*内容全域の背景色*/
  }

  table tbody tr:hover {
    background-color: aliceblue;
    /*選択時の背景色*/
  }

  table th,
  table td {
    padding: .35em .75em;
    border-bottom: 1px solid #eee;
    /*横線の色、太さ*/
  }

  table thead th {
    font-size: .85em;
    padding: 1em;
  }

  table thead tr {
    background-color: #3399FF;
    /*項目の背景色*/
    color: #fff;
  }

  table tbody th {
    text-align: left;
    /*全体の寄せ*/
    font-size: .8em;
  }

  .nomal_table {
    width: 1200px;

  }

  .nomal_table th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;

  }

  .nomal_table td {
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;

  }

  .line_table {
    width: 1200px;
  }

  .line_table th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;

  }

  .line_table td {
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #000000;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #000000;

  }


  /*テーブル背景色(交互) oddで奇数、evenで偶数に対して指定（02-4データ互換性）*/
  .t-line th {
    font-weight: bold;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;

  }

  .t-line tr:nth-child(odd) td {
    background-color: aliceblue;
  }

  .t-line tr:nth-child(even) td {
    background-color: #CAEEFF
  }

  /*テーブル背景色(交互) oddで奇数、evenで偶数に対して指定（02-1担当者）*/
  .t-tantou th {
    background-color: #00bb85;
    font-weight: bold;
    font-family: HG丸ｺﾞｼｯｸM-PRO;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000000;
    border-left-color: #000000;
  }


  .t-tantou tr:nth-child(odd) td {
    background-color: #ffffe5;
    text-align: left;
    height: 53px;

  }

  .t-tantou tr:nth-child(even) td {
    background-color: #e5ffe5;
    text-align: left;
    height: 53px;

  }


  @media screen and (max-width: 600px) {
    table {
      width: 100%;
    }
  }


  .titlefont {
    font-weight: normal;

  }

  /*部材セット等DL表*/
  .ex1 {
    line-height: 20px;
  }

  .ex2 {
    /*２段重ねの表はこれを使うこと*/
    line-height: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablename {
    font-size: 16pt;
    width: 1200px;
    margin-bottom: 10px;
    border-bottom: inherit;
    border-bottom-color: #C0C0C0;
    border-bottom-style: solid;
    border-bottom-width: 5px;
  }

  .table-ul ul {
    padding-left: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px
      /*
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px
    */
      /*↑padding: 10px 10px 10px 30pxと
    まとめて書くこともできますね。*/
      /*背景色などのCSSは省略*/
  }

  /*********/
  /***** tatami ****/
  /*********/

  Summary {
    display: black;
    list-style: none;
    user-select: none;
  }

  Summary::before {
    content: "▲";
    color: #889CDE;
    font-size: 120%;
    position: relative;
  }

  details[open] summary::before {
    content: "▼";
  }
}

// modal
.no-scroll {
  overflow: hidden;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

#media-modal {
  background-color: red($color: #000000);

  #modal-iframe {
    width: 70vw;
    height: 80vh;
  }
}
