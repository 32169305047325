  .navbar {
    .dash-logo {
      width: 200px;
      margin-right: 2rem;
    }

    #logout {
      background-color: #394242;
    }
  }

  #admin {
    margin-top: 100px;
    min-height: 100vh;

    /* bootstrap上書き */
    .accordion-button {
      font-size: 2rem;
    }
  }
