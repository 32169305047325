@use 'common';

#user {
  header {
    height: 105px;
    display: flex;
    align-items: center;
    z-index: 100;

    .logo {
      width: 100%;
      max-width: 500px;
    }
  }

  #header-menu {
    height: 60px;
    background-color: common.$blue;

    position: relative;

    .menu {
      width: 100%;
      min-width: 0%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: auto;
      list-style: none;
      padding: 0;
      margin-right: calc(-.5 * var(--bs-gutter-x));
      margin-left: calc(-.5 * var(--bs-gutter-x));

      .caret {
        display: none;
      }

      .dropdown {
        height: 100%;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 400;
        position: static;
        border-left: 1px solid common.$gray;

        &:last-child {
          border-right: 1px solid common.$gray;
        }

        .dropdown-toggle {
          height: 100%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .3s ease;

          &:after {
            border: none;
            margin: 0;
          }

          &:hover,
          &.show {
            background-color: common.$lightblue;
            transition: all .3s ease;
            position: relative;
          }

          &.show:after {
            position: absolute;
            bottom: -.7rem;
            z-index: 110;
            width: 0;
            height: 0;
            transition: all .1s ease;
            border-style: solid;
            border-width: .7rem .7rem 0 .7rem;
            border-color: common.$lightblue transparent transparent transparent;
          }
        }

        .dropdown-menu {
          position: absolute !important;
          transform: none !important;
          inset: 60px 0 0 !important;
          width: 100%;
          margin: 0;
          border-radius: 0;
          z-index: 20;

          .container-wrapper {
            background-color: #fff;
            border-bottom: 1px solid common.$blue;
            padding: 1rem 2rem;
          }

          .category {
            border-right: 1px solid common.$gray;
            padding: 1.5rem;

            &:last-child {
              border-right: none;
            }

            h4 {
              font-size: 1.2rem;
              font-weight: 600;
              border-left: 5px solid common.$blue;
              padding-left: .5rem;

              a {
                color: common.$blue;
              }

              &.current-menu {
                border-color: common.$orange;

                a {
                  color: common.$orange;
                }
              }
            }

            ul {
              list-style: none;
              margin-top: .8rem;
              padding-left: .5rem;

              li {
                color: #333;

                &:before {
                  display: inline-block;
                  margin-left: .2rem;
                  vertical-align: .2rem;
                  content: "";
                  border-top: .3rem solid transparent;
                  border-right: none;
                  border-bottom: .3rem solid transparent;
                  border-left: .3rem;
                }

                a {
                  color: #333;
                  padding: 2px;

                  &:before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 1;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent;
                    border-width: .3rem .5rem;
                    border-left-color: currentColor;
                    border-right: 0;
                    padding-right: .3rem;
                  }

                  &:hover,
                  &.current-menu {

                    &:before {
                      content: "";
                      display: inline-block;
                      vertical-align: middle;
                      line-height: 1;
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-color: transparent;
                      border-width: .3rem .5rem;
                      border-left-color: common.$red;
                      border-right: 0;
                      padding-right: .3rem;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  h2.title {
    font-size: 1.6rem;
    display: flex;
    font-weight: 500;
    align-items: center;

    &::before {
      content: '';
      width: 1.5rem;
      height: 5px;
      background-color: common.$blue;
      margin-right: .5em;
    }

    span {
      font-size: 1rem;

    }
  }

  .contents {
    background-color: common.$gray;

    &.info {
      background-image: url(../images/user/bg_v1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .contents-header {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;

    h1.title {
      font-size: 2.5rem;
      text-shadow: 1px 1px 0px common.$gray;
      line-height: 150px;
    }
  }

  #top {
    .contents {
      &.rebrod {
        max-height: 600px;
        background-image: url(../images/user/top_rebrod_bg.png);
        background-repeat: repeat-x;
        background-position: center;
        background-size: 100%;
        text-align: center;
        vertical-align: middle;
      }

      .rebrod-logo {
        width: 100%;
        height: auto;
      }

    }
  }

  #rebro {
    .contents-header {
      background-image: url(../images/user/rebrod_header.png);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    }

    .contents {
      background-image: url(../images/user/rebrod_introduction_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .logo {
        width: 70%;
        max-width: 450px;
      }

      .capture {
        width: 70%;
        max-width: 600px;
      }

      .rebro-link-title {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .rebro-link-title {
        font-size: 1rem;
        padding-bottom: .5rem;
        border-bottom: 3px solid #cfcfcf;
        margin-left: 5px;

        &::before {
          content: '■';
          color: common.$blue;
          font-size: 1.5rem;
        }
      }
    }
  }

  #sitemap {
    a {
      color: common.$darkgray;
    }

    h4 {
      font-size: 1.2rem;
      border-bottom: 1px solid common.$darkgray;
      padding-bottom: .5rem;
    }

    h5 {
      font-size: 1.1rem;
      border-left: 5px solid common.$blue;
      padding-left: .5rem;

      &.current-menu {
        border-color: common.$orange;
      }

    }

    ul {
      list-style-type: none;
      padding-left: 1.2rem;
      padding-top: .6rem;
      padding-bottom: .7rem;

      a {

        &:hover,
        &.current-menu {

          &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: .3rem .5rem;
            border-left-color: common.$red;
            border-right: 0;
            margin-left: -1rem;
            padding-right: 0.5rem;
          }
        }
      }
    }
  }
}
