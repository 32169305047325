/* 最新情報フレーム */
#info_frame {
	background-color: #fff;
	max-height: 320px;
	overflow-y: scroll;
	padding: 10px;

	dl {
		width: 100%;
		padding: 10px;
		margin: 10px auto;
		border-bottom: 1px solid #ddd;
		display: table;
	}

	dt {
		display: table-cell;
		vertical-align: top;
		width: 0;
		white-space: nowrap;
		padding-right: 1.5rem;
		position: relative;

		.new {
			width: 80%;
			position: absolute;
			top: -12px;
			display: inline-block;
			color: #f00;
			border: 1px solid #f00;
			padding: 0px 5px;
			font-size: 0.8rem;
			line-height: 100%;
			text-align: center;
		}
	}

	dd {
		display: table-cell;
		vertical-align: top;
	}

	dd:nth-child(2) {
		width: 0;
		white-space: nowrap;
		padding-right: 1.5rem;
	}

	dd:nth-child(3) {}

	dd>a {
		text-decoration: none;
	}

	span[class^="label"] {
		display: inline-block;
		padding: 1px 8px;
		border-radius: 3px;
		white-space: nowrap;
		text-align: center;
	}

	/*新着ラベル*/
	.label1 {
		/*部材ﾌｫｰﾏｯﾄ*/
		background-color: #ff9500;
		color: #FFFFFF;
	}

	.label2 {
		/*rebro情報*/
		background-color: #f62e36;
		color: #fff;
	}

	.label3 {
		/*BIM関連*/
		background-color: #8f76d6;
		color: #fff;
	}

	.label4 {
		/*三機関連*/
		background-color: #00bb85;
		color: #fff;
	}

	.label5 {
		/*使い方機能*/
		background-color: #d7c447;
		color: #000000;
	}

	.label6 {
		/*その他*/
		background-color: #0079c2;
		color: #FFFFFF;
	}

	.label7 {
		/*その他*/
		background-color: black;
		color: #fff;
	}

}
