$orange: #e07500;
$blue : #0368b3;
$lightblue : #4399dc;
$red: #f00;
$lightred: rgb(239, 187, 187);
$gray: #efefef;
$darkgray: #333;

body {
	font-family: "Helvetica Neue",
		Arial,
		"Hiragino Kaku Gothic ProN",
		"Hiragino Sans",
		Meiryo,
		sans-serif;
	height: 100%;
	font-size: 16px;
}

.bg-orange {
	background-color: $orange;
	color: #fff;
}

.bg-blue {
	background-color: $blue;
	color: #fff;
}

.btn {
	border-radius: 0px;
	padding-top: 1rem;
	padding-bottom: 1rem;

	&.btn-orange {
		background-color: $orange;
		color: #fff;
	}

	&.btn-blue {
		background-color: $blue;
		color: #fff;
	}
}

.flash-contents {
	position: absolute;
	top: 0;
	padding: 10px;
}

footer {
	height: 170px;
	background-color: $gray;
	background-image: url(../images/user/footer_bg.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	display: flex;
	align-items: flex-end;

	.copyright {
		width: 100%;
		background-color: #0268b4;
		color: #fff;
		vertical-align: middle;
		text-align: center;
		padding: 5px;
	}
}
