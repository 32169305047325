//@import '~@fortawesome/fontawesome-free/scss/fontawesome';

body {
	margin: 0;
	height: 100vh;
}

.login-form {
	width: 100%;
	height: 100%;
	max-width: 880px;
	max-height: 425px;
	padding: 10px;
}

#user_login {
	height: 100vh;
	width: 100%;
	background-image: url(../images/user/login_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	align-items: center;
	justify-content: center;

	.login-logo {
		height: 100%;
		background-image: url(../images/user/login_logo.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 70%;
	}


}

#admin-login {
	height: 100vh;
	width: 100%;
	background-image: url(../images/admin/login_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;

	.login-logo {
		background-image: url(../images/admin/login_logo.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 70%;
	}

}
